/* Define color variables */
:root {
  --primary-color: #0068d9;
  --secondary-color: #003384;
  --hover-secondary-color: #2e599c;
  --hover-primary-color: #3386e1;
  --font-color: #292e32;
  --font-color2: #848e96;
  --font-color3: #333333;
  --icon-color: #727a80;
  --background-color: #f2f7fa;
  --background-color2: #e5f0fb;
  --background-color3: #f9f9f9;
  --white-font-color: #fbfbfb;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('./Gilroy-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('./Gilroy-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./Gilroy-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('./Gilroy-ExtraBold.ttf') format('opentype');
}


* {
  font-family: "Open Sans", sans-serif;
}

.extrabold-font{
  font-family: 'Gilroy-ExtraBold', sans-serif;
  line-height: 50px;
}

.regular-font{
  font-family: 'Gilroy-Regular', sans-serif;
}

.light-font{
  font-family: 'Gilroy-Regular', sans-serif;
}

h4, h3, h2, h1{
  font-family: 'Gilroy-ExtraBold', sans-serif;
}

.learn-more{
  font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.16px;
}

.spinner {
  color: var(--primary-color);
}

.font-color-primary {
  color: var(--secondary-color);
}

.logo {
  display: flex;
  align-items: center;
}

.header {
  height: 96px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.main-color {
  color: var(--primary-color) !important;
}

.second-color {
  color: var(--secondary-color) !important;
}

.main-button {
  background-color: var(--primary-color);
  padding: 12px 30px;
  border-radius: 100px;
  color: var(--white-font-color);
  font-size: 18px;
  border: 2.5px solid var(--primary-color);
  transition: background-color 0.3s ease;
}

.main-button:hover {
  background-color: var(--hover-primary-color);
}

.main-button-reverse {
  border: 2.5px solid var(--secondary-color) !important;
  padding: 12px 40px;
  border-radius: 100px;
  color: var(--secondary-color);
  background-color: var(--background-color) !important;
  font-size: 18px;
  border: none;
  transition: background-color 0.3s ease;
}

.main-button-reverse:hover {
  background-color: var(--hover-secondary-color) !important;
  color: var(--white-font-color) !important;
}

.weight-600 {
  font-weight: 600 !important;
}

.weight-500 {
  font-weight: 500 !important;
}

.weight-700 {
  font-weight: 700 !important;
}

.weight-800 {
  font-weight: 800 !important;
}

.size-40 {
  font-size: 40px !important;
}

.size-30 {
  font-size: 30px;
}

.size-16 {
  font-size: 16px;
}

.size-18 {
  font-size: 18px;
}

.special-bg {
  background-color: var(--background-color2);
  padding: 4rem 0px;
  border-radius: 20px;
}

.special-bg-5 {
  background-color: var(--background-color);
  padding: 6rem 0px;
  border-radius: 20px;
}

.special-bg-2 {
  background-color: var(--background-color2);
  padding: 4rem 0px;
}

.special-bg-3 {
  background-color: var(--background-color);
  padding: 4rem 0px;
}

.special-bg-4 {
  background-color: var(--background-color3);
  padding: 1rem 0px;
}

.icon {
  color: var(--icon-color);
  cursor: pointer;
  transition: all 0.3s;
}

.icon:hover {
  transition: all 0.3s;
  color: var(--hover-primary-color);
}

.quote-content {
  color: var(--font-color);
  font-size: 2rem;
  font-weight: 400;
  line-height: 140%;
  max-width: 700px;
}

.menu-footer {
  font-size: 14px;
  color: var(--font-color3);
  text-decoration: underline;
  transition: all 0.3s;
  cursor: pointer;
  list-style: none;
}

.menu-footer:hover {
  color: var(--hover-primary-color);
}

.res-menu-button:hover {
  color: var(--hover-primary-color);
  transition: all 0.3s;
  cursor: pointer;
}

.res-menu-item {
  flex-direction: column;
  position: relative !important;
  background-color: white;
  width: 100%;
  z-index: 200;
  top: 0px;
  left: 0px;
  padding: 2rem;
  text-align: center;
  width: 100% !important;
  /* height: 1; */
}

.icon-x {
  position: absolute;
  right: 25px;
}

.quote-name {
  color: var(--secondary-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 3px;
}

.quote-name-title {
  font-size: 12px;
  color: var(--font-color);
}

.subheading {
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

.detail, li {
  color: var(--font-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.detail2 {
  color: var(--font-color);
  font-size: 18px;
  font-style: normal;
  max-width: 900px;
  font-weight: 400;
  line-height: 160%;
}

.hero-font {
  font-size: 3rem !important;
}

.hero-font-2{
  font-size: 1.2rem !important;
color: var(--primary-color) !important;
  font-weight: 500 !important;
  
}

.image-shadow-2 {
  mix-blend-mode: multiply;
}

.line-height-120 {
  line-height: 120%;
}

.text-color {
  color: var(--font-color) !important;
}

.margin-2 {
  margin-bottom: 2rem;
}

.margin-1 {
  margin-bottom: 1rem;
}

.award {
  max-width: 80%;
  max-height: 80%;
  height: auto;
}

.content-top-bottom {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.content-bottom {
  margin-bottom: 65px !important;
}

.pad-bottom-24 {
  padding-bottom: 24px;
}

.custom-card {
  border: 1px solid #ededed;
  border-radius: 20px;
  background: #fff;
  transition: all 0.2s;
  min-height: 248px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  box-shadow: none !important;
  transition: all 0.3s;
}
.card-body {
  padding: 21px !important;
}
.custom-card > img {
  border-radius: 20px 20px 0px 0px;
}

.custom-card:hover {
  box-shadow: 3px 3px 31px rgba(33, 33, 33, 0.1) !important;
}

.subtitle {
  margin-bottom: 12px;
  color: var(--font-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.post-head {
  color: var(--font-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
}

.post-head-2 {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  font-family: 'Gilroy-ExtraBold', sans-serif;
}

.hero-size {
  height: 600px;
}

.text-animation-container {
  overflow: hidden;
  height: 100%;
}

.text-animation {
  animation: fadeIn 0.5s ease-in-out;
  /* font-family: 'Gilroy-ExtraBold' !important; */
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.heading-paragraph {
  color: var(--font-color);
  text-align: center;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  max-width: 880px;
  margin: auto;
}

.verticle-tabs {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.verticle-tab-label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.verticle-tab-label > p {
  color: var(--font-color2);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
  position: relative;
  top: 5px;
  cursor: pointer;
}

.menu-top-margin {
  margin-top: 44px;
}

.active > p {
  color: var(--primary-color);
}

li {
  list-style-type: none;
}

.component-bottom-margin {
  margin-bottom: 130px;
}

.component-bottom-margin-2 {
  margin-bottom: 70px;
}

.info-bar__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.info-bar {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: auto;
  font-weight: 500;
  color: var(--background-color);
  min-height: 64px;
  background-size: cover;
  background-repeat: no-repeat;
}

.info-bar__content {
  display: flex !important;
  margin-top: 1rem;
}

.mk-footer-copyright {
  color: var(--font-color3);
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
}

.width-fix-big{
  min-width: 260px;
  display: flex;
  gap:20px;
  justify-content: end;
}

.width-fix-big-logo{
  min-width: 260px;
  display: flex;
  gap:20px;
  justify-content: start;
}

@media only screen and (max-width: 1000px) {
  .display-none-resp {
    display: none !important;
  }
  .display-block-resp {
    display: block !important;
  }
  .responsive-header {
    flex-direction: column;
  }
  .margin-top-resp {
    margin-top: 1.5rem;
  }
  .width-fix-big,.width-fix-big-logo {
    min-width: auto;
  }
}

@media only screen and (max-width: 478px) {
  .w-100-resp{
    width: 100% !important;
    margin: 10px 0px !important;
  }
  .text-center-resp{
    text-align: center;
  }
  .custom-padding-resp{
    padding: 30px 14px !important;
  }
}

@media only screen and (min-width: 1000px) {
  .res-menu-button {
    display: none !important;
  }
  .res-menu-item {
    display: none !important;
  }
}

@media only screen and (max-width: 500px) {
  .hero-font {
    font-size: 2rem !important;
  }
  .min-width-resp {
    min-width: 350px !important;
  }
  .ml-20px {
    margin-left: 10px !important;
  }
  .book-demo-resp {
    padding: 12px 0px !important;
    border-radius: 100px;
    text-decoration: underline;
    text-underline-offset: 0.3em;
    color: var(--primary-color) !important;
    background-color: white !important;
    font-size: 16px;
    border: none;
    transition: background-color 0.3s ease;
  }
  .alert-text-resp {
    font-size: 14px !important;
  }
  .component-bottom-margin-s{
    margin-bottom: 200px !important;
  }
}

@media only screen and (max-width: 300px) {
  .hero-font {
    font-size: 2rem !important;
  }
  .min-width-resp {
    min-width: 200px !important;
  }
  .ml-20px {
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 767px) {
  .responsive-flex {
    flex-direction: column-reverse !important;
  }
  .responsive-align{
    align-items: center;
  }
  .header-os{
    font-size: 40px !important;
    line-height: 40px !important;
    letter-spacing: 1px !important;
  }
  .component-bottom-margin-s{
    margin-bottom: 400px;
  }
}

.ml-20px {
  margin-left: 20px;
}

.right-arrow {
  transition: all 0.3s;
  cursor: pointer;
}

.learn-more:hover .right-arrow {
  margin-left: 6px;
  transition: all 0.3s;
}

.right-arrow:hover {
  margin-left: 6px;
  transition: all 0.3s;
}

.cursor-pointer {
  cursor: pointer;
}

.active-nav {
  color: var(--hover-primary-color) !important;
  font-weight: 500;
  transition: all 0.3s;
}

.nav-item:hover {
  color: var(--hover-primary-color);
  transition: all 0.3s;
}

.nav-item {
  transition: all 0.3s;
  font-weight: 500;
  list-style: none;
  text-decoration: none;
  color: var(--font-color);
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  background-color: white !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.book-demo-body {
  background-color: var(--secondary-color);
}

.font-white {
  color: white !important;
}

.label-form {
  color: #6d7278;
  font-size: 16px;
  font-weight: 600;
}

.label-star {
  color: red;
}

.demo-form {
  background-color: white;
  border-radius: 16px;
  padding: 50px 0px;
}

/* Pricing CSS*/

.price-section {
  border-radius: 16px;
  box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.subheading-price {
  font-size: 13px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.78px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.tier-desc {
  min-height: 81.56px;
}

.eyebrow-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.78px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.price {
  color: var(--black);
  font-size: 39px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 120%;
  letter-spacing: -0.39px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-block;
}

.p-small,
span.sub-list-title {
  font-size: 13px;
  line-height: 160%;
}

.price-button {
  background-color: var(--primary-color);
  padding: 12px 30px;
  border-radius: 6px;
  width: 100%;
  color: var(--white-font-color);
  font-size: 18px;
  border: none;
  transition: background-color 0.3s ease;
}

.price-button:hover {
  background-color: var(--hover-primary-color);
}

.border-sp {
  border-bottom: 0.5px solid #e6e6e6;
}

.feature-row:not(.pkg-header) {
  border-top: 1px solid #e6e6e6;
}

.list-style-none {
  list-style: none;
  padding: 0px !important;
}

.custom-card-price {
  border: 1px solid #ededed;
  border-radius: 20px;
  background: #fff;
  transition: all 0.2s;
  min-height: 148px;
  box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  height: 100%;
  width: 100%;
  transition: all 0.3s;
}

.faq-question {
  border-radius: 0px !important;
  background-color: white !important;
  padding-top: 50px;
  padding-bottom: 50px;
}

.faq-answer {
  border-radius: 0px !important;
  background-color: white !important;
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: justify;
  text-justify: inter-word;
  padding-bottom: 20px;
}

li{
  margin-bottom: 12px !important;
}

.accordion-button {
  outline: none !important;
  padding: 0px;
  background-color: white !important;
  box-shadow: none !important;
  color: var(--font-color3) !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.form-control:focus,
select:focus {
  border: 1px solid var(--icon-color) !important;
  box-shadow: none !important;
}

/* Header Menu CSS*/

.Icon-nav-bar{
  background-color: var(--background-color3);
  border: 1px solid var(--font-color2);
  border-radius: 100%;
  width:47px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align:center;
}

h3 {
  color: var(--secondary-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 8px;
  margin-bottom: 20px;
}

h4 {
  color: var(--black);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin: 0;
}


.p-menu {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
}

.bg-menu-nav-item{
  background-color: var(--background-color2);
  border-radius: 0px 20px 20px 0px;
  width: 400px;
}


.col-featured__img {
  background-size: cover;
  width: 100%;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0 15px 0 0;
}

.hover-item-nav-menu:hover > h4 {
  color: var(--primary-color);
  transition: color 0.2s ease;
}

.hover-item-nav-menu{
  cursor: pointer;
}

.menu-item-nav{
  position: absolute;
  border-radius: 14px;
  background-color: var(--background-color);
  top:95px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid rgba(16, 24, 40, 0.05);
}

.margin-nav-items{
  margin: 30px 0px;
}

.margin-left-30{
  margin-left: 30px;
}

.min-width-200{
  min-width: 300px !important;
}

.header-os{
  font-size: 4rem;
  font-weight: 700;
  line-height: 85px;
  color: var(--primary-color);
  letter-spacing: -3px;
  transition: all 0.3s;
  font-family: 'Gilroy-ExtraBold';
}

.body-os{
  background-color: var(--secondary-color);
  background: linear-gradient(45deg,  rgba(20, 118, 251, 0.1) ,rgba(177, 165, 215, 0.1));
  border: 1.2px solid var(--primary-color);
  padding: 50px;
  border-radius: 20px;
  transition: all 0.3s;
  height: 100%;
}

.detail-os li{
  color:var(--font-color);
  font-family: 'Gilroy-Medium';
  font-size: 1.2rem;
  transition: all 0.3s;
}

.plus-button{
  border: none;
  border-radius: 0.5rem;
  padding: 17px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-plus{
  color: var(--hover-primary-color);
}


.list-spans{
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 5px 8px;
  min-width: max-content;
  transition: all 0.3s ease;
  background-color: var(--background-color2)
}

.list-spans:hover{
  transition: all 0.3s ease;
  border: 1px solid var(--primary-color);
}

.icon-x-list{
  color: red;
  margin-left: 8px;
  width: 18px;
  cursor: pointer;
}

.font-list{
  font-size: 14px;
}

.icon-tooltip{
  width: 20px !important;
  color: var(--primary-color);
  cursor: pointer;
}

.modal-dialog{
  max-width: 280px;
}